import React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const Button = loadable(() => import('./Button'))
const FormLink = loadable(() => import('./FormLink'))
const ProfitRoomLink = loadable(() => import('./ProfitRoomLink'))

const ButtonOutput = ({ button, colour }) => {
  if (button._type === 'formLink') {
    return (
      <FormLink
        linkLabel={button.linkLabel}
        form={button.form}
        type="buttonGreen"
        extraClasses="mx-1"
      />
    )
  }
  if (button._type === 'profitRoomLink') {
    return (
      <ProfitRoomLink
        colour={button.colour}
        label={button.label}
        profitRoomId={button.profitRoomId}
        type={button.type}
      />
    )
  }
  return (
    <Button
      to={button.link}
      colour={colour}
      rel={button.rel}
      target={button.target}
      images={button.gallery?.images}
      showGalleryIcon={button._type === 'galleryLink'}
      isHeading
    >
      {button.label || button.linkLabel}
    </Button>
  )
}

const Buttons = ({ buttons }) => (
  <div className="mt-8 buttons">
    {buttons.map(button => {
      let { link } = button

      if (link) {
        if (!button.link.endsWith('/')) {
          link = `${button.link}/`
        }
      }

      const colour = button._type === 'galleryLink' ? 'blue' : button.colour

      return (
        <div key={button._key} className="py-1 xs:py-0">
          <ButtonOutput colour={colour} button={button} />
        </div>
      )
    })}
  </div>
)

export default Buttons

Buttons.propTypes = {
  buttons: PropTypes.array,
}

ButtonOutput.propTypes = {
  button: PropTypes.object,
  colour: PropTypes.string,
}
